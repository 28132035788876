<template>
  <van-index-bar>
    <div v-for="floor in floors" :key="floor.name">
      <van-index-anchor :index="floor.name" />
      <van-cell
        v-for="bed in floor.beds"
        :title="bed"
        :key="bed"
        @click="() => setBed(bed)"
      />
    </div>
  </van-index-bar>
</template>

<script>
export default {
  data() {
    const floors = []
    for (let i = 0; i < 26; ++i) {
      const beds = []
      const code = String.fromCharCode(65 + i)
      for (let j = 1; j < 11; ++j) {
        beds.push(`${code}${j.toString().padStart(2, '0')}`)
      }
      floors.push({
        name: code,
        beds
      })
    }
    return {
      floors
    }
  },

  methods: {
    setBed(bed) {
      this.$store.dispatch('setBed', bed)
      this.$router.go(-1)
    }
  }
}
</script>

<style>
</style>